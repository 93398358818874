.root {
  display: flex;
  width: 100%;
  height: 100%;
  line-height: 18px;
  font-weight: 700;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.content {
  overflow: visible;
  line-height: 1.4;
}

.noWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.pill {
  padding: 0 12px;
}

.pill .content {
  color: var(--mb-color-brand);
  border: 1px solid var(--mb-color-brand-light);
  background-color: var(--mb-color-brand-lighter);
  border-radius: 99px;
  padding: 2px 8px;
  width: auto;
  min-width: 30px;
  text-overflow: ellipsis;
  text-align: center;
}
